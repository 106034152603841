<template>
    <div class="relative">
        <select :id="id" v-model="modelValue" @change="$emit('update:modelValue', $event.target.value)" class="form-control color-black peer">
            <option value="" />
            <option v-for="(state, abbreviation) in states" :key="abbreviation" :value="abbreviation">{{ state }}</option>
        </select>
        <float-label :for="id" :value="modelValue">{{ label }}</float-label>
        <vue-feather :type="icon" class="w-4 h-4 inline-block absolute top-1/3 -translate-y-1 mt-[5px] left-[10px] text-cappuccino" />
    </div>
    <div class="mb-5">
        <span class="field-error" v-if="!modelValue && error">{{ error && error[0] }}</span>
    </div>
</template>

<script setup>
    import { ref, defineProps } from 'vue';
    import FloatLabel from "@/components/forms/FloatLabel.vue";

    const props = defineProps({
        id: String,
        icon: String,
        label: String,
        error: Array,
        modelValue: String,
    });

    const states = {
        "AC": "Acre",
        "AL": "Alagoas",
        "AP": "Amapá",
        "AM": "Amazonas",
        "BA": "Bahia",
        "CE": "Ceará",
        "DF": "Distrito Federal",
        "ES": "Espírito Santo",
        "GO": "Goiás",
        "MA": "Maranhão",
        "MT": "Mato Grosso",
        "MS": "Mato Grosso do Sul",
        "MG": "Minas Gerais",
        "PA": "Pará",
        "PB": "Paraíba",
        "PR": "Paraná",
        "PE": "Pernambuco",
        "PI": "Piauí",
        "RJ": "Rio de Janeiro",
        "RN": "Rio Grande do Norte",
        "RS": "Rio Grande do Sul",
        "RO": "Rondônia",
        "RR": "Roraima",
        "SC": "Santa Catarina",
        "SP": "São Paulo",
        "SE": "Sergipe",
        "TO": "Tocantins",
    };

    const modelValue = ref(props.modelValue || '');

</script>
