<template>
    <div v-if="toastMessages" class="fixed z-[80] top-[90px] right-[20px]">
        <TransitionGroup name="list" tag="ul">
            <!-- <li class="bg-opacity-85 p-2 mb-2 radius-xl shadow-[0_15px_20px_-12px_rgba(0,0,0,.4)]" :class="getIconClass(toast)" v-for="toast in toastMessages" key="toad.id">
            </li> -->
            <li v-for="toast in toastMessages" key="toad.id" class="flex items-center w-full max-w-xs p-4 mb-2 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
                <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg" :class="getIconClass(toast)">

                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 9v4m0 4h.01M4.93 19h14.14c1.54 0 2.35-1.7 1.55-3L13.55 4c-.8-1.3-2.3-1.3-3.1 0L3.38 16c-.8 1.3.01 3 1.55 3Z"
                        />
                    </svg>

                    <span class="sr-only">Alert icon</span>
                </div>
                <div class="ms-3 text-sm font-normal" :class="getTextClass(toast)">{{ toast.message }}</div>
            </li>
        </TransitionGroup>
    </div>
</template>

<script setup>
import { useUIStore } from "@/stores/uiStore";
import { storeToRefs } from "pinia";
const uiStore = useUIStore();
const { toastMessages } = storeToRefs(uiStore);

const getIconClass = (toast) => {
    if (toast.type === "fail") {
        return "text-red-500 bg-red-100 dark:bg-red-800 dark:text-red-200";
    }

    if (toast.type === "success") {
        return "text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-200";
    }

    return "bg-cyan-400 text-black";
};

const getTextClass = (toast) => {
    if (toast.type === "fail") {
        return "text-red-500";
    }

    if (toast.type === "success") {
        return "text-green-500";
    }

    return "bg-cyan-400 text-black";
};
</script>

<style scoped></style>
