import { createApp } from "vue";
import { createPinia } from "pinia";

import vueFeather from "vue-feather";
import VueTippy from 'vue-tippy'

import HeroImage from '@/components/HeroImage.vue';
import Terms from '@/components/Terms.vue';

import App from "@/App.vue";
import router from "@/router";

import "@/assets/css/index.css";

const app = createApp(App);

app.use(VueTippy, {
    defaultProps: { placement: 'top' },
});

app.use(createPinia());

app.component('hero-image', HeroImage);
app.component('term-link', Terms);

app.component(vueFeather.name, vueFeather);

app.use({
    directive: 'tippy',
    component: 'tippy',
    componentSingleton: 'tippy-singleton',
    defaultProps: {
        placement: 'auto-end',
        allowHTML: true,
    },
});

app.use(router);
app.mount("#app");
