<template>
    <div ref="turnstile" data-theme="light" class="turnstile-container"></div>
</template>

<script setup>
import { onMounted, watch, defineProps, defineEmits, ref } from "vue";

const props = defineProps({
    siteKey: {
        type: String,
        required: true,
    },
    action: {
        type: String,
        default: "",
    },
    resetTrigger: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['verified', 'reset']);

const turnstile = ref(null);
const turnstileToken = ref(null);
const turnstileRef = ref(null);

const handleVerification = (token) => {
    turnstileToken.value = token;
    emit("verified", token);
};

const resetTurnstile = () => {
    if (window.turnstile) {
        window.turnstile.reset(turnstileRef.value);
        emit('reset');
    }
};

watch(() => props.resetTrigger, (newValue) => {
    if (newValue && window.turnstile) {
        window.turnstile.reset(turnstile.value);
        emit("reset");
    }
});

onMounted(() => {
    renderTurnstile();
});

const renderTurnstile = () => {
    if (window.turnstile) {
        window.turnstile.render(turnstile.value, {
            sitekey: props.siteKey,
            action: props.action,
            theme: 'light',
            callback: handleVerification,
            'error-callback': resetTurnstile,
            'expired-callback': resetTurnstile,
        });
    }
};

</script>
