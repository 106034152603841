<template>

    <transition name="fade">

        <div>

            <div class="w-full flex flex-col-reverse lg:flex-row gap-5 mx-auto">

                <div class="bg-like pt-20 pb-10 px-4 bg-cappuccino rounded-md leading-tight w-full text-center flex flex-col mx-auto items-center">
                    <div class="relative lg:top-1/2 lg:-translate-y-1/2 px-5 lg:px-10">
                        <img src="@/assets/svgs/like.svg" alt="" class="mx-auto lg:max-w-[180px]" />
                        <p class="text-28 lg:text-36 font-black mt-3">Agora é só<br />cruzar os dedos!</p>
                        <p class="text-16 sm:text-18 lg:text-20 font-semibold">Obrigado por compartilhar seu jeitinho de celebrar com a gente!</p>
                    </div>
                </div>

                <div class="block mx-auto w-full h-auto">
                    <hero-image />
                </div>

            </div>

            <p class="text-center mt-14 mx-auto max-w-[550px]">
                Sua participação irá fazer a ceia de uma pessoa mais feliz neste Natal! Saiba mais sobre a Ação da Cidadania <a href="https://www.acaodacidadania.org.br/" target="_blank" class="underline font-bold">aqui</a>.
            </p>

            <div class="flex flex-col lg:flex-row gap-6 mt-14 hidden lg:flex">

                <div class="card relative">

                    <div class="mt-20">
                        <div class="top-6 -left-2 absolute">
                            <img src="@/assets/images/fita.png" height="57" width="272"/>
                        </div>
                        <div class="absolute z-index-[10] top-8  ml-8 text-white font-bold">
                            Como Participar
                        </div>
                    </div>

                    <div>
                        <div class="number-sm">
                            <span class="font-bold">1</span>
                        </div>
                        <p class="ml-8">Cadastre-se aqui na página até o dia 15 de dezembro.</p>
                    </div>

                    <div>
                        <div class="number-sm">
                            <span class="font-bold">2</span>
                        </div>
                        <p class="ml-8">Envie sua receita especial de Bacalhau Riberalves.</p>
                    </div>

                    <div>
                        <div class="number-sm">
                            <span class="font-bold">3</span>
                        </div>
                        <p class="ml-8">Conte-nos a história por trás do seu prato e aumente suas chances de ganhar.</p>
                    </div>

                </div>

                <div class="card relative">

                        <div class="mt-20">
                            <div class="top-6 -left-2 absolute">
                                <img src="@/assets/images/fita.png" height="57" width="272"/>
                            </div>
                            <div class="absolute z-index-[10] top-8  ml-8 text-white font-bold">
                                Prêmios
                            </div>
                        </div>

                        <div class="relative">

                            <div class="number-sm mt-1 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-dark-blue z-10" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clip-rule="evenodd" />
                                </svg>
                            </div>

                            <p class="ml-8"><span class="font-bold">1º Lugar:</span> Você e um acompanhante em uma viagem de 5 dias para Portugal com tudo incluído.</p>

                        </div>

                        <div class="relative flex">
                            <div class="number-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-dark-blue z-10" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clip-rule="evenodd" />
                                </svg>
                            </div>
                            <p class="ml-8">
                                <span class="font-bold">2º e 3º lugar:</span> Vale Produtos Bacalhau Riberalves.
                            </p>
                        </div>

                    </div>

            </div>

        </div>

    </transition>

</template>
