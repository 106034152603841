<template>

    <transition name="fade">

        <div>

            <div v-if="page === 'home'">
                <Intro @register="toRegister" />
            </div>

            <div v-if="page === 'register'">
                <Register @confirmation="toConfirmation" />
            </div>

            <div v-if="page === 'confirmation'">
                <Confirmation />
            </div>

            <div v-if="page === 'closed'">
            </div>

            <div v-if="page === 'result'">
            </div>

            <term-link />

        </div>

    </transition>


</template>

<script setup>

import { ref } from "vue";

import Intro from "@/views/Intro.vue";
import Register from "@/views/Register.vue";
import Confirmation from "@/views/Confirmation.vue";

const page = ref("home");

const toRegister = () => {
    page.value = "register";
};

const toConfirmation = () => {
    page.value = "confirmation";

    // Disparar evento no GTM
    if (window.dataLayer) {
        window.dataLayer.push({
            event: "Cadastro - Concurso natal 24",
            page: "confirmation",
            timestamp: new Date().toISOString(),
        });
    } else {
        console.warn("dataLayer não está definido");
    }

    // Disparar evento no Pixel da Meta
    if (typeof fbq === "function") {
        fbq("track", "Cadastro - Concurso natal 24");
    } else {
        console.warn("fbq não está definido.");
    }

};

</script>
