<template>

    <Wrapper class="px-3 lg:px-6" v-cloak>

        <div class="w-full max-w-[1140px] p-2 mt-5 mb-10 lg:mt-10 lg:mb-20 mx-auto">

            <a href="/"><img src="@/assets/svgs/logo.svg" class="w-full max-w-[267px] block mx-auto mb-10" alt="Bacalhau Riberalves"  /></a>

            <div class="flex flex-col justify-center items-center">

                <transition name="fade" mode="out-in">
                    <router-view />
                </transition>

                <Loading />

            </div>

        </div>

    </Wrapper>

    <Toast />

    <transition name="fade">
        <OptInPopup v-if="showPopup" :isChecked="consentOptIn" @optin:close="closePopup" />
    </transition>

</template>
<script setup>

import { ref, onMounted } from "vue";
import Loading from "@/components/Loading.vue";
import { notFound } from "@/router";

import Wrapper from "@/components/Wrapper.vue";
import NotFound from "@/views/NotFound.vue";
import OptInPopup from "@/components/OptInPopup.vue";
import Toast from "@/components/Toast.vue";

const showPopup     = ref(false);
const consentOptIn  = ref(false);

const openPopup = () => {
    if (!localStorage.hasOwnProperty("consentOptIn")) {
        showPopup.value = false;
    }
};

const closePopup = (value) => {
    consentOptIn.value = value;
    localStorage.setItem("consentOptIn", consentOptIn.value);
    showPopup.value = false;
};

onMounted(() => {
    const checkIfLoaded = () => {
        if (document.readyState === "complete") {
            openPopup();
        } else {
            setTimeout(checkIfLoaded, 1500);
        }
    };
    checkIfLoaded();
});
</script>
