import { ref } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import NotFound from "@/views/NotFound.vue";

const routes = [
    { path: "/", name: "home", component: Home },
    { path: "/:pathMatch(.*)*", name: "not-found", redirect: "/" },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

export const notFound = ref(false);

router.afterEach(async (to, from) => {
    if (notFound.value === false) {
        notFound.value = to.matched.length === 0;
    }
});

export default router;
