<template>

    <div class="relative border-l-2 border-cappuccino ml-4 pl-8 pb-8">

        <div v-if="currentStep <= 1" class="number">
            <span class="font-bold">1</span>
        </div>

        <div v-else class="number flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-dark-blue z-10" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clip-rule="evenodd" />
            </svg>
        </div>

        <h2 class="font-bold">Informações pessoais*</h2>
        <p>Cadastre-se até 15 de dezembro.</p>

        <form v-if="currentStep <= 1" @submit.prevent="step1" autocomplete="off" class="mt-5 mb-10 border-cappuccino">

            <InputField id="name" v-model="formData.name" label="Nome" icon="user" :error="formErrors?.name" />
            <InputField id="email" v-model="formData.email" label="E-mail" icon="mail" :error="formErrors?.email" />
            <PhoneField id="phone" v-model="formData.phone" label="Telefone" icon="phone" :maxlength="15" :error="formErrors?.phone" />

            <InputField
                id="cpf"
                v-model="formData.cpf"
                label="CPF"
                icon="credit-card"
                maxlength="11"
                :error="formErrors.cpf"
            />

            <SelectStateField id="state" v-model="formData.state" label="Estado" icon="map-pin" :error="formErrors?.state" />

            <div class="flex items-center">
                <input type="checkbox" id="accept_terms" v-model="formData.accept_terms" class="h-4 w-4 text-green-600 rounded bg-gray-800 border-gray-600 focus:ring-green-500" />
                <label for="accept_terms" class="ml-2 block text-sm normal-case">
                    <span>Li e estou de concordo com o <a href="/files/regulamento.pdf" class="colo-white underline" target="_blank">regulamento</a> do concurso.</span>
                </label>
            </div>

            <div class="mb-5">
                <span class="field-error" v-if="formErrors?.accept_terms">{{ formErrors.accept_terms[0] }}</span>
            </div>

            <LoadingButton type="submit" :isLoading="isLoading" :class="{ '!opacity-100': accept_terms }">
                <span>Próximo passo</span>
            </LoadingButton>

        </form>

    </div>

    <div class="relative border-l-2 border-cappuccino ml-4 pl-8 pb-8">

        <div v-if="currentStep == 2" class="number">
            <span class="font-bold">2</span>
        </div>

        <div v-else class="number flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-dark-blue z-10" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clip-rule="evenodd" />
            </svg>
        </div>

        <h2 class="font-bold">Sua receita*</h2>
        <p>Submeta uma receita com Bacalhau Riberalves.</p>

        <form v-if="currentStep == 2" @submit.prevent="step2" autocomplete="off" class="mt-5 mb-10">

            <div class="mb-6">

                <label class="block text-sm font-bold mb-2">
                    Escreva aqui sua receita ou faça upload <span class="text-10">(PDF ou Imagem)</span>*
                </label>

                <textarea v-model="formData.recipe_content" class="form-control" placeholder="Digite sua receita aqui..." />

                <div class="mt-4 flex-col items-center">

                    <input type="file" accept=".pdf,.jpg,.jpeg,.png" @change="handleRecipeUpload" class="hidden" id="recipeUpload" />

                    <div class="flex flex-row w-full whitespace-nowrap rounded px-1 py-1 bg-white input-border justify-between items-center">
                        <div class="text-xs bg-white mx-1 rounded px-2 py-1 overflow-hidden text-ellipsis whitespace-nowrap max-w-[255px]">
                            <span v-if="formData.recipe_file">{{ formData.recipe_file.name }}</span>
                        </div>
                        <label for="recipeUpload" class="cursor-pointer bg-[#d1c4a3] text-white font-bold py-2 px-4 rounded hover:bg-[#bba885] flex justify-center items-center gap-2">
                            <vue-feather type="upload" class="w-4 h-4 inline-block" />
                            <span class="text-xs">Selecionar arquivo</span>
                        </label>
                    </div>

                    <div v-if="formErrors?.recipe_content" class="mb-5">
                        <span class="field-error">{{ formErrors.recipe_content[0] }}</span>
                    </div>

                </div>

            </div>

            <!-- Upload de fotos -->
            <div class="mb-6">

                <label class="block text-sm font-bold mb-2">
                    Anexe até 5 fotos relacionadas ao prato <span class="text-10">(máximo de 2MB)</span>.
                </label>

                <div class="bg-white w-full p-2 rounded">
                    <div class="border-2 border-dashed border-brown rounded p-6 text-center hover:border-dark-blue focus-within:border-blue-dark relative" @drop.prevent="handleImageDrop" @dragover.prevent>
                        <input type="file" accept=".jpg, .jpeg, .png" multiple class="absolute inset-0 opacity-0 cursor-pointer" @change="handleImageUpload" />
                        <div class="text-center mx-auto w-full flex flex-col justify-center items-center mt-3">
                            <svg width="58" height="46" viewBox="0 0 58 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M53.3952 2.29713C53.0701 1.03213 51.4913 0 49.8834 0H8.11948C6.50871 0 4.92987 1.03213 4.60771 2.29713L4.02435 5.75H53.9756L53.3952 2.29713ZM56.3178 8.625H1.68221C1.44778 8.62515 1.21598 8.67381 1.00164 8.76785C0.787298 8.86189 0.595149 8.99924 0.4375 9.17111C0.279851 9.34297 0.160178 9.54556 0.0861458 9.76589C0.012114 9.98623 -0.014644 10.2195 0.00758575 10.4506L2.6864 43.999C2.73976 44.5465 2.997 45.0548 3.40798 45.4247C3.81897 45.7946 4.35426 45.9997 4.90955 46H53.0905C53.6457 45.9997 54.181 45.7946 54.592 45.4247C55.003 45.0548 55.2602 44.5465 55.3136 43.999L57.9924 10.4506C58.0146 10.2195 57.9879 9.98623 57.9139 9.76589C57.8398 9.54556 57.7201 9.34297 57.5625 9.17111C57.4049 8.99924 57.2127 8.86189 56.9984 8.76785C56.784 8.67381 56.5522 8.62515 56.3178 8.625ZM36.0772 18.3281C36.7992 18.3281 37.4917 18.6122 38.0022 19.118C38.5128 19.6237 38.7996 20.3097 38.7996 21.0249C38.7996 21.7401 38.5128 22.426 38.0022 22.9318C37.4917 23.4375 36.7992 23.7216 36.0772 23.7216C35.3552 23.7216 34.6628 23.4375 34.1522 22.9318C33.6417 22.426 33.3549 21.7401 33.3549 21.0249C33.3549 20.3097 33.6417 19.6237 34.1522 19.118C34.6628 18.6122 35.3552 18.3281 36.0772 18.3281ZM15.9412 34.5L23.1562 18.0723L31.361 31.2283L38.3962 27.7668L42.0618 34.5H15.9412Z" fill="#DCD5C1"/>
                            </svg>
                            <div class="flex flex-col items-center justify-center mt-4 font-semibold">
                                <div class="flex items-center justify-center px-4 py-2 space-x-2 bg-beige rounded transition">
                                    <vue-feather type="upload" class="w-4 h-4 inline-block" />
                                    <span class="text-xs">Carregar imagens</span>
                                </div>
                                <p class="text-xs font-medium mt-2">ou soltar um arquivo</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="formErrors?.recipe_images">
                    <span class="field-error">{{ formErrors.recipe_images[0] }}</span>
                </div>

                <div class="mt-4 flex flex-row flex-wrap gap-2">
                    <div v-for="(image, index) in formData.recipe_images" :key="index" class="relative w-20 w-20">
                        <img :src="image.preview" alt="" class="w-20 h-20 object-cover rounded-md"/>
                        <button class="absolute top-1 right-1 bg-red-500 text-white rounded-md w-6 p-1 text-xs" @click="removeImage(index)">✕</button>
                    </div>
                </div>

            </div>

            <LoadingButton type="submit" :isLoading="isLoading">
                <span>Próximo passo</span>
            </LoadingButton>

        </form>

    </div>

    <div class="relative ml-4 pl-8">

        <div v-if="currentStep == 3" class="number">
            <span class="font-bold">3</span>
        </div>

        <div v-else class="number flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-dark-blue z-10" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clip-rule="evenodd" />
            </svg>
        </div>

        <div>

            <div>
                <h2 class="font-bold">História da receita</h2>
                <p>Compartilhe a história por trás da receita.</p>
            </div>

            <form v-if="currentStep == 3" @submit.prevent="step3" autocomplete="off" class="mt-5">

                <div class="mb-6">

                    <label class="block text-sm font-bold mb-2">
                        Conte a história da receita! Opcional *
                    </label>

                    <textarea v-model="formData.recipe_history" class="form-control" placeholder="Digite sua historia aqui..." />

                </div>

                <div class="block">
                    <Turnstile :siteKey="siteKey" :resetTrigger="turnstileReset" @verified="handleVerification" @reset="resetToken" class="pb-4" />
                </div>

                <LoadingButton type="submit" :isLoading="isLoading" :disabled="!isVerified">
                    <span>Finalize sua participação</span>
                </LoadingButton>

            </form>

        </div>

    </div>

</template>

<script setup>

import { ref, defineProps, defineEmits } from "vue";
import { useUIStore } from "@/stores/uiStore";
import { useRegistrationFormStore } from "@/stores/registrationFormStore";
import { isValidCPF, isValidPhoneNumber, isValidEmail } from "@/utils/utils";

import axios from "axios";

import FloatLabel from "@/components/forms/FloatLabel.vue";
import InputField from "@/components/forms/InputField.vue";
import SelectStateField from "@/components/forms/SelectStateField.vue";
import PhoneField from "@/components/forms/PhoneField.vue";
import DateField from "@/components/forms/DateField.vue";
import LoadingButton from "@/components/forms/LoadingButton.vue";
import Turnstile from "@/components/Turnstile.vue";

const uiStore = useUIStore();
const registrationFormStore = useRegistrationFormStore();

const { formData, resetForm } = registrationFormStore;
const { addLoadingItem, removeLoadingItem } = uiStore;

defineProps({
    currentStep: Number,
    formData: Object,
});

const emit = defineEmits(["update:currentStep", "update:formData", "registration-complete"]);

const completeRegistration = () => {
    emit("registration-complete");
};

const updateFormData = (field, value) => {
    emit("update:formData", { ...formData, [field]: value });
};

const currentStep = ref(1);

// currentStep 1

const siteKey           = ref("0x4AAAAAAA0wH7czaJN0QjWN");
const isVerified        = ref(false);
const turnstileToken    = ref(null);
const turnstileReset    = ref(false);

const formErrors        = ref({});
const isLoading         = ref(false);

const handleVerification = (receivedToken) => {
    turnstileToken.value = receivedToken;
    turnstileReset.value = false;
    isVerified.value = true;
};

const resetToken = () => {
    turnstileToken.value = null;
    isVerified.value = false;
    turnstileReset.value = true;
};

const step1 = async () => {

    if (validateStep1()) {
        currentStep.value = 2;
        emit("update:currentStep", currentStep.value);
    }

};

const step2 = async () => {
    if (validateStep2()) {
        currentStep.value = 3;
        emit("update:currentStep", currentStep.value);
    }
};

const step3 = async () => {

    const API_URI = import.meta.env.VITE_API_URL;

    if (!isVerified.value) {
        uiStore.addToastMessages("Por favor, complete a validação.", "fail");
        return;
    }

    if (!validateStep1()) {
        currentStep.value = 1;
        return;
    }

    if (!validateStep2()) {
        currentStep.value = 2;
        return;
    }

    try {

        addLoadingItem();

        const payload = new FormData();

        payload.append("name", formData.name);
        payload.append("email", formData.email);
        payload.append("phone", formData.phone);
        payload.append("cpf", formData.cpf);
        payload.append("state", formData.state);
        payload.append("recipe_title", formData.recipe_title);
        payload.append("recipe_content", formData.recipe_content);
        payload.append("recipe_history", formData.recipe_history);
        payload.append("turnstile_token", turnstileToken.value);

        if (formData.recipe_file) {
            payload.append("recipe_file", formData.recipe_file);
        }

        formData.recipe_images.forEach((img, index) => {
            payload.append(`recipe_images[${index}]`, img.file);
        });

        const response = await axios.post(`${API_URI}/register`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        // uiStore.addToastMessages("Formulário enviado com sucesso!", "success");
        emit("registration-complete");
        resetForm();

    } catch (error) {

        //console.error("Erro ao enviar o formulário:", error);
        uiStore.addToastMessages(error.response?.data?.message || "Erro desconhecido.", "fail");

    } finally {

        resetToken();
        removeLoadingItem();

    }

};

// validation
const validateStep1 = () => {
    formErrors.value = {};
    const { name, email, phone, cpf, state, accept_terms } = formData;
    if (!name || name.trim().length === 0) formErrors.value.name = ["O campo nome é obrigatório"];
    if (!email || email.trim().length === 0) {
        formErrors.value.email = ["O campo e-mail é obrigatório"];
    } else if (!isValidEmail(email)) {
        formErrors.value.email = ["Digite um e-mail válido"];
    }
    if (!cpf || cpf.trim().length !== 11 && cpf.trim().length !== 14) {
        formErrors.value.cpf = ["O campo cpf é obrigatório"];
    } else if (!isValidCPF(cpf)) {
        formErrors.value.cpf = ["Digite um número de CPF válido"];
    }
    if (!phone || phone.trim().length === 0) {
        formErrors.value.phone = ["O campo telefone é obrigatório"];
    } else {
        if (!isValidPhoneNumber(phone)) formErrors.value.phone = ["O número de telefone é inválido"];
    }
    if (!state) formErrors.value.state = ["O campo estado é obrigatório"];
    if (!accept_terms || accept_terms !== true) formErrors.value.accept_terms = ["Você precisa ler e concordar com os termos de uso"];
    return Object.keys(formErrors.value).length <= 0;
};

const validateStep2 = () => {
    formErrors.value = {};
    const { recipe_content, recipe_file, recipe_images } = formData;
    // console.log(recipe_content, recipe_file);
    if ((!recipe_content || recipe_content.trim().length === 0) && !recipe_file) {
        //uiStore.addToastMessages(`Você precisa preencher o texto ou enviar um arquivo da receita.`, "fail");
        formErrors.value.recipe_content = ["Você precisa preencher o texto ou enviar um arquivo da receita."];
    }
    /*
    if (recipe_images.length === 0) {
        //uiStore.addToastMessages(`Por favor, anexe ao menos uma foto do prato.`, "fail");
        formErrors.value.recipe_images = ["Por favor, anexe ao menos uma foto do prato."];
    }
    */
    return Object.keys(formErrors.value).length <= 0;
};

const handleRecipeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        formData.recipe_file = file;
    }
};

const handleImageUpload = (event) => {
    const files = event.target.files;
    addImages(files);
};

const handleImageDrop = (event) => {
    const files = event.dataTransfer.files;
    addImages(files);
};

const addImages = (files) => {
    const maxFiles = 5;
    const maxSize = 2 * 1024 * 1024; // 2MB
    if (formData.recipe_images.length + files.length > maxFiles) {
        uiStore.addToastMessages(`Você só pode carregar até ${maxFiles} imagens.`, "fail");
        return;
    }
    for (const file of files) {
        if (file.size > maxSize) {
            uiStore.addToastMessages(`O arquivo ${file.name} excede o tamanho máximo de 2MB.`, "fail");
            continue;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            formData.recipe_images.push({
                file: file,
                preview: e.target.result,
            });
        };
        reader.readAsDataURL(file);
    }
};

const removeImage = (index) => {
    formData.recipe_images.splice(index, 1);
};

</script>
