// stores/registrationFormStore.js
import { defineStore } from "pinia";
import { ref } from "vue";

export const useRegistrationFormStore = defineStore("registrationForm", () => {

    const currentStep = ref(1);

    const formData = ref({
        name                : "",
        email               : "",
        phone               : "",
        cpf                 : "",
        state               : "",
        accept_terms        : false,
        recipe_title        : "",
        recipe_content      : "",
        recipe_history      : "",
        recipe_file         : null,
        recipe_images       : [],
        turnstile_token     : '',
    });

    const resetForm = () => {
        currentStep.value = 1;
        formData.value = {
            name                : "",
            email               : "",
            phone               : "",
            cpf                 : "",
            state               : "",
            accept_terms        : false,
            recipe_title        : "",
            recipe_content      : "",
            recipe_history      : "",
            recipe_file         : null,
            recipe_images       : [],
            turnstile_token     : '',
        };
    };

    const nextStep = () => {
        currentStep.value += 1;
    };

    const prevStep = () => {
        if (currentStep.value > 1) currentStep.value -= 1;
    };

    return {
        currentStep,
        formData,
        resetForm,
        nextStep,
        prevStep,
    };

});
