<template>

    <div class="mx-auto flex flex-col lg:flex-row justify-center gap-16 pb-10 lg:gap-8">

        <div class="w-full">
            <hero-image class="w-full" />
        </div>

        <div class="w-full lg:border-2 lg:border-dark-blue lg:rounded-lg lg:p-6 lg:pr-10">

            <h2 class="font-bold text-28 pb-8 leading-tight lg:text-36">Mostre seu jeitinho de celebrar:</h2>

            <RegistrationForm
                :currentStep="currentStep"
                :formData="formData"
                @update:formData="formData = $event"
                @update:currentStep="currentStep = $event"
                @registration-complete="registrationComplete"
            />

        </div>

    </div>

    <div class="w-full lg:hidden">
        <hero-image class="relative w-full z-10" />
        <div class="bg-sand relative rounded-lg px-6 pt-12 pb-10 -mt-5 z-[5]">
            <p class="text-20 font-black mt">Sua receita é um gesto de solidariedade!</p>
            <p class="text-18">Para cada receita enviada, a Riberalves vai doar uma refeição para a ONG Ação da Cidadania, para fazer a ceia de uma pessoa mais feliz neste Natal!</p>
        </div>
    </div>


</template>

<script setup>
import { ref, defineEmits } from "vue";
import { useUIStore } from "@/stores/uiStore";

import RegistrationForm from "@/views/RegistrationForm.vue";

const uiStore = useUIStore();
const emit = defineEmits(["confirmation"]);
const isLoading = ref(false);

const registrationComplete = () => {
    console.log('Register::registrationComplete');
    emit('confirmation');
};

</script>
