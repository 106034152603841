<template>
    <transition name="fade">
        <div v-if="hasLoading" class="p-5 fixed z-[100] top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-black bg-opacity-65">
            <div class="loading">
                <div class="ajax-loader">
                    <div class="ajax-loader-circle">
                        <svg class="ajax-loader-circle-svg" viewBox="0 0 500 500">
                            <circle cx="250" cy="250" r="239" />
                        </svg>
                    </div>
                </div>
            </div>
            <h2 class="text-18 p-4 text-white uppercase">{{ textLoading }}</h2>
        </div>
    </transition>
</template>

<script setup>

import { useUIStore } from "@/stores/uiStore";
import { storeToRefs } from "pinia";

const uiStore = useUIStore();

const { hasLoading, textLoading } = storeToRefs(uiStore);

</script>

<style scoped>
    .ajax-loader {
        position: relative;
        width: 100px;
        height: 100px;
        transform-origin: 50%, 50%;
        -webkit-animation: ajaxLoaderSpin 1.4s linear infinite;
        animation: ajaxLoaderSpin 1.4s linear infinite;
    }

    .ajax-loader-circle-svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform-origin: 50%, 50%;
        -webkit-animation: ajaxLoaderDashSpin 1.4s ease-in-out infinite;
        animation: ajaxLoaderDashSpin 1.4s ease-in-out infinite;
    }

    circle {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        stroke: #ffffff;
        -webkit-animation: ajaxLoaderDash 1.4s ease-in-out infinite;
        animation: ajaxLoaderDash 1.4s ease-in-out infinite;
        stroke-dasharray: 1570;
        stroke-width: 19;
        fill: none;
    }

    @-webkit-keyframes ajaxLoaderSpin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(270deg);
        }
    }
    @keyframes ajaxLoaderSpin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(270deg);
        }
    }
    @-webkit-keyframes ajaxLoaderColors {
        0% {
            stroke: #35ad0e;
        }
        25% {
            stroke: #d8ad44;
        }
        50% {
            stroke: #d00324;
        }
        75% {
            stroke: #dc00b8;
        }
        100% {
            stroke: #017efc;
        }
    }
    @keyframes ajaxLoaderColors {
        0% {
            stroke: #35ad0e;
        }
        25% {
            stroke: #d8ad44;
        }
        50% {
            stroke: #d00324;
        }
        75% {
            stroke: #dc00b8;
        }
        100% {
            stroke: #017efc;
        }
    }
    @-webkit-keyframes ajaxLoaderDash {
        0% {
            stroke-dashoffset: 1413;
        }
        50% {
            stroke-dashoffset: 392.5;
        }
        100% {
            stroke-dashoffset: 1413;
        }
    }
    @keyframes ajaxLoaderDash {
        0% {
            stroke-dashoffset: 1413;
        }
        50% {
            stroke-dashoffset: 392.5;
        }
        100% {
            stroke-dashoffset: 1413;
        }
    }
    @-webkit-keyframes ajaxLoaderDashSpin {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(135deg);
        }
        100% {
            transform: rotate(450deg);
        }
    }
    @keyframes ajaxLoaderDashSpin {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(135deg);
        }
        100% {
            transform: rotate(450deg);
        }
    }


</style>
