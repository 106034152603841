<template>

    <div class="hidden lg:block">

        <div class="w-full flex gap-5 mx-auto">

            <div class="p-10 border-2 border-dark-blue rounded-md leading-tight max-w-[455px]">
                <p class="text-28 mt-1 font-light">Mostre seu jeitinho de celebrar com Riberalves e<br /> <span class="font-black">concorra a uma viagem com acompanhante!</span></p>
                <p class="text-20">Toda família tem uma receita especial com Bacalhau, cheia de sabor e tradição, e a Riberalves quer conhecer a sua!</p>
                <div class="flex flex-col mt-10">
                    <button id="btn-participate" @click="participate()" class="act-participate btn relative max-w-[230px]">
                        <span class="mx-auto pt-1">Participe agora</span>
                    </button>
                </div>
            </div>

            <div class="block mx-auto w-full h-auto">
                <hero-image />
            </div>

        </div>

        <div class="flex flex-row gap-6 mt-14">

            <div class="card relative">

                <div class="mt-20">
                    <div class="top-6 -left-2 absolute">
                        <img src="@/assets/images/fita.png" height="57" width="272"/>
                    </div>
                    <div class="absolute z-index-[10] top-8  ml-8 text-white font-bold">
                        Como Participar
                    </div>
                </div>

                <div>
                    <div class="number-sm">
                        <span class="font-bold">1</span>
                    </div>
                    <p class="ml-8">Cadastre-se aqui na página até o dia 15 de dezembro.</p>
                </div>

                <div>
                    <div class="number-sm">
                        <span class="font-bold">2</span>
                    </div>
                    <p class="ml-8">Envie sua receita especial de Bacalhau Riberalves.</p>
                </div>

                <div>
                    <div class="number-sm">
                        <span class="font-bold">3</span>
                    </div>
                    <p class="ml-8">Conte-nos a história por trás do seu prato e aumente suas chances de ganhar.</p>
                </div>

            </div>

            <div class="card relative">

                <div class="mt-20">
                    <div class="top-6 -left-2 absolute">
                        <img src="@/assets/images/fita.png" height="57" width="272"/>
                    </div>
                    <div class="absolute z-index-[10] top-8  ml-8 text-white font-bold">
                        Prêmios
                    </div>
                </div>

                <div class="relative">

                    <div class="number-sm mt-1 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-dark-blue z-10" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clip-rule="evenodd" />
                        </svg>
                    </div>

                    <p class="ml-8"><span class="font-bold">1º Lugar:</span> Você e um acompanhante em uma viagem de 5 dias para Portugal com tudo incluído.</p>

                </div>

                <div class="relative flex">
                    <div class="number-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-dark-blue z-10" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <p class="ml-8">
                        <span class="font-bold">2º e 3º lugar:</span> Vale Produtos Bacalhau Riberalves.
                    </p>
                </div>

            </div>

        </div>

    </div>

    <div class="block lg:hidden">

        <div class="block mx-auto w-full h-auto mt-5 mb-8">
            <hero-image />
        </div>

        <p class="text-18">Mostre seu jeitinho de celebrar com Riberalves e <span class="font-extrabold">concorra a uma viagem com acompanhante!</span></p>
        <p class="text-18">Toda família tem uma receita especial com Bacalhau, cheia de sabor e tradição, e a Riberalves quer conhecer a sua!</p>

        <div class="flex flex-col mt-8 mb-6">
            <button @click="participate()" class="btn relative w-full mb-5">
                <span class="mx-auto pt-1">Participe agora</span>
            </button>
        </div>

        <div class="flex flex-col gap-6">
            <div class="card relative">

                <div class="mt-20">
                    <div class="top-6 -left-2 absolute">
                        <img src="@/assets/images/fita.png" height="57" width="272"/>
                    </div>
                    <div class="absolute z-index-[10] top-8  ml-8 text-white font-bold">
                        Como Participar
                    </div>
                </div>

                <div>
                    <div class="number-sm">
                        <span class="font-bold">1</span>
                    </div>
                    <p class="ml-8">Cadastre-se aqui na página até o dia 15 de dezembro.</p>
                </div>

                <div>
                    <div class="number-sm">
                        <span class="font-bold">2</span>
                    </div>
                    <p class="ml-8">Envie sua receita especial de Bacalhau Riberalves.</p>
                </div>

                <div>
                    <div class="number-sm">
                        <span class="font-bold">3</span>
                    </div>
                    <p class="ml-8">Conte-nos a história por trás do seu prato e aumente suas chances de ganhar.</p>
                </div>

            </div>
            <div class="card relative">

                <div class="mt-20">
                    <div class="top-6 -left-2 absolute">
                        <img src="@/assets/images/fita.png" height="57" width="272"/>
                    </div>
                    <div class="absolute z-index-[10] top-8  ml-8 text-white font-bold">
                        Prêmios
                    </div>
                </div>

                <div class="relative">

                    <div class="number-sm mt-1 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-dark-blue z-10" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clip-rule="evenodd" />
                        </svg>
                    </div>

                    <p class="ml-8"><span class="font-bold">1º Lugar:</span> Você e um acompanhante em uma viagem de 5 dias para Portugal com tudo incluído.</p>

                </div>

                <div class="relative flex">
                    <div class="number-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-dark-blue z-10" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <p class="ml-8">
                        <span class="font-bold">2º e 3º lugar:</span> Vale Produtos Bacalhau Riberalves.
                    </p>
                </div>

            </div>
        </div>

    </div>

</template>

<script setup>
import { defineEmits } from "vue";
const emit = defineEmits(["register"]);
const participate = async () => {
    console.log('Intro::participate');
    emit("register");
};
</script>
