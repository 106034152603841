<template>
    <div class="relative">
        <input type="tel" :id="id" :value="modelValue" @input="handleInput" :maxlength="maxlength" class="form-control peer" />
        <float-label :for="id" :value="modelValue">{{ label }}</float-label>
        <vue-feather :type="icon" class="w-4 h-4 inline-block absolute top-1/2 -translate-y-1/2 left-[10px] text-cappuccino" />
    </div>
    <div class="mb-5">
        <span class="field-error" v-if="error">{{ error[0] }}</span>
    </div>
</template>

<script setup>
    import { defineProps, defineEmits } from 'vue';
    import FloatLabel from "@/components/forms/FloatLabel.vue";
    import { formatPhone } from '@/utils/utils';

    // Props
    const { id, label, icon, error, maxlength, modelValue } = defineProps({
        id: String,
        label: String,
        icon: String,
        error: String,
        maxlength: {
            type: Number,
            default: Infinity,
        },
        modelValue: {
            type: [String, Number],
            default: '',
        },
    });

    const emit = defineEmits();

    const handleInput = (event) => {
        const formattedValue = formatPhone(event.target.value);
        emit('update:modelValue', formattedValue);
    };
</script>


