const formatDate = (value) => {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");
    return value;
};

const formatPhone = (value) => {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d\d)(\d)/g, "($1) $2");
    value = value.replace(/(\d{5})(\d)/, "$1-$2");
    return value;
};

const isValidDate = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    if (cleanedValue.length !== 8) {
        return false;
    }
    const day = parseInt(cleanedValue.substring(0, 2));
    const month = parseInt(cleanedValue.substring(2, 4));
    const year = parseInt(cleanedValue.substring(4, 8));
    const isValidDate = (d, m, y) => {
        const date = new Date(y, m - 1, d);
        return (
            date.getDate() === d &&
            date.getMonth() + 1 === m &&
            date.getFullYear() === y
        );
    };
    return isValidDate(day, month, year);
};

const isValidPhoneNumber = (value) => {
    const phoneRegex = /^\(\d{2}\) \d{5}-\d{4}$/;
    return phoneRegex.test(value);
};

const isValidCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, '');
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        return false;
    }
    let sum = 0, remainder;
    for (let i = 1; i <= 9; i++) {
        sum += parseInt(cpf.charAt(i - 1)) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.charAt(9))) return false;
    sum = 0;
    for (let i = 1; i <= 10; i++) {
        sum += parseInt(cpf.charAt(i - 1)) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.charAt(10))) return false;
    return true;
};

const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};


export { formatDate, isValidEmail, isValidCPF, isValidDate, formatPhone, isValidPhoneNumber }
